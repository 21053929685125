import { IconComponent } from "@/ui/types";

export const XDEFIWallet: IconComponent = (props) => {
	return (
		<svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0 6.39161C0 2.86162 2.86162 0 6.39161 0H25.6084C29.1384 0 32 2.86162 32 6.39161V25.6084C32 29.1384 29.1384 32 25.6084 32H6.39161C2.86162 32 0 29.1384 0 25.6084V6.39161Z"
				fill="url(#paint0_linear_5147_39366)"
			/>
			<path
				d="M17.9111 17.198C16.4278 18.1222 14.4423 18.5981 12.4314 18.4964C10.7408 18.4133 9.35416 17.8033 8.51574 16.8006C7.77866 15.9042 7.49304 14.7213 7.68652 13.3604C7.7521 12.9082 7.88649 12.4687 8.08501 12.0574L8.11265 11.9996C8.80858 10.6557 9.8414 9.51667 11.1094 8.69468C12.3775 7.87269 13.8371 7.39605 15.3445 7.31166C16.852 7.22728 18.3553 7.53807 19.7067 8.21343C21.0581 8.88879 22.2108 9.90546 23.0515 11.1634C23.8922 12.4213 24.3919 13.8771 24.5013 15.3875C24.6108 16.898 24.3263 18.4109 23.6758 19.7776C23.0253 21.1442 22.0312 22.3174 20.7915 23.1817C19.5517 24.046 18.1089 24.5716 16.6051 24.7068L16.6973 25.7673C18.3856 25.6164 20.0057 25.027 21.3979 24.057C22.79 23.0871 23.9062 21.7701 24.6366 20.2358C25.3669 18.7015 25.6861 17.0028 25.5628 15.3071C25.4395 13.6114 24.8779 11.9771 23.9334 10.5653C22.9888 9.15357 21.6939 8.01297 20.1761 7.25591C18.6584 6.49886 16.9702 6.15146 15.2779 6.24795C13.5856 6.34444 11.9475 6.88149 10.5251 7.80618C9.10267 8.73087 7.94494 10.0113 7.16597 11.5214L7.12911 11.5976C6.88285 12.1081 6.71659 12.6536 6.63619 13.2149C6.40586 14.8737 6.76288 16.3501 7.69574 17.4868C8.71613 18.7298 10.3769 19.4691 12.3693 19.5662C14.7947 19.6886 17.2063 19.0255 18.9177 17.8056L17.9111 17.198Z"
				fill="white"
			/>
			<path
				d="M19.9398 18.4041C18.9746 19.245 16.7312 20.7699 13.0089 20.9778C8.84216 21.2089 7.10542 19.8504 7.0893 19.8365L6.75301 20.2524L7.0916 19.8434L6.41211 20.6613C6.48582 20.7237 8.15114 22.073 12.0623 22.073C12.3824 22.073 12.7187 22.073 13.0688 22.0452C17.5673 21.7934 20.0388 19.8249 20.9003 18.984L19.9398 18.4041Z"
				fill="white"
			/>
			<path
				d="M21.7454 19.5039C21.1748 20.2563 20.4723 20.8983 19.6724 21.3984C16.8531 23.2236 13.2668 23.4593 10.7561 23.3322L10.7031 24.3973C11.1246 24.4181 11.53 24.4273 11.9239 24.4273C19.0044 24.4273 21.8652 21.1928 22.6645 20.0376L21.7431 19.4946"
				fill="white"
			/>
			<path
				d="M21.4768 15.4712C21.9462 15.4712 22.3267 15.0895 22.3267 14.6187C22.3267 14.1479 21.9462 13.7662 21.4768 13.7662C21.0075 13.7662 20.627 14.1479 20.627 14.6187C20.627 15.0895 21.0075 15.4712 21.4768 15.4712Z"
				fill="white"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_5147_39366"
					x1="13.7762"
					y1="-3.85649"
					x2="-11.5874"
					y2="19.3182"
					gradientUnits="userSpaceOnUse">
					<stop stopColor="#4476F2" />
					<stop offset="1" stopColor="#2041E0" />
				</linearGradient>
			</defs>
		</svg>
	);
};
