import { IconComponent } from "@/ui/types";

export const Frame: IconComponent = (props) => {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="155.675 61.509 131.262 131.262">
			<g transform="matrix(1, 0, 0, 1, 155.675343, 61.509203)">
				<g>
					<rect
						width="131.262"
						height="131.262"
						stroke="rgb(0, 0, 0)"
						fill="rgb(36, 33, 42)"
						rx="23.779"
						ry="23.779"
					/>
				</g>
				<g>
					<path
						d="M 96.592 65.008 L 96.592 37.229 C 96.589 34.771 94.595 32.781 92.137 32.784 L 64.263 32.784 C 63.983 32.783 63.715 32.673 63.516 32.477 L 60.163 29.125 C 59.96 28.918 59.682 28.802 59.392 28.804 L 31.531 28.804 C 29.074 28.799 27.077 30.787 27.072 33.243 L 27.072 61.023 C 27.071 61.302 27.184 61.57 27.383 61.766 L 30.737 65.118 C 30.936 65.314 31.048 65.581 31.048 65.861 L 31.048 93.64 C 31.049 96.097 33.044 98.087 35.501 98.084 L 63.376 98.084 C 63.656 98.085 63.924 98.195 64.124 98.391 L 67.476 101.744 C 67.676 101.94 67.944 102.05 68.224 102.051 L 96.093 102.051 C 98.551 102.056 100.548 100.068 100.552 97.611 L 100.552 69.83 C 100.553 69.552 100.441 69.284 100.241 69.089 L 96.889 65.736 C 96.699 65.541 96.591 65.28 96.592 65.008 Z M 77.53 79.706 L 50.148 79.706 C 49.807 79.705 49.53 79.434 49.52 79.094 L 49.52 51.793 C 49.525 51.447 49.802 51.167 50.148 51.157 L 77.53 51.157 C 77.88 51.159 78.164 51.443 78.166 51.793 L 78.166 79.094 C 78.164 79.444 77.879 79.726 77.53 79.726 L 77.53 79.706 Z"
						fill="rgba(255, 255, 255, 0.9)"
					/>
				</g>
			</g>
		</svg>
	);
};
