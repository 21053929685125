import { http } from "viem";

export const curtis = {
	contracts: {
		// readonly multicall3: {
		// 	readonly address: `0x${string}`;
		// 	readonly blockCreated: 16146628;
		// };
	},
	blockExplorers: {
		default: {
			name: "Curtis Explorer",
			url: "https://curtis.explorer.caldera.xyz",
			apiUrl: "https://curtis.explorer.caldera.xyz/api/v2",
		},
	},
	id: 33111,
	name: "Curtis",
	nativeCurrency: {
		name: "ApeCoin",
		symbol: "APE",
		decimals: 18,
	},
	rpcUrls: {
		default: {
			http: ["https://curtis.rpc.caldera.xyz/http"],
			wss: ["wss://curtis.rpc.caldera.xyz/ws"],
		},
	},
	// readonly sourceId?: number | undefined;
	// readonly testnet?: boolean | undefined;
	// readonly custom?: Record<string, unknown> | undefined;
	// readonly formatters?: undefined;
	// readonly serializers?: import("viem").ChainSerializers<undefined> | undefined;
	// readonly fees?: import("viem").ChainFees<undefined> | undefined;
} as const;

export const publicTransports = {
	33111: http(curtis.rpcUrls.default.http[0]),
} as const;

export const publicChains = [
	{
		blockExplorers: {
			default: {
				name: "Curtis Explorer",
				url: "https://curtis.explorer.caldera.xyz",
				apiUrl: "https://curtis.explorer.caldera.xyz/api/v2",
			},
		},
		contracts: {
			// readonly ensRegistry: {
			// 	readonly address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e";
			// };
			// readonly ensUniversalResolver: {
			// 	readonly address: "0xce01f8eee7E479C928F8919abD53E553a36CeF67";
			// 	readonly blockCreated: 19258213;
			// };
			// readonly multicall3: {
			// 	readonly address: "0xca11bde05977b3631167028862be2a173976ca11";
			// 	readonly blockCreated: 14353601;
			// };
		},
		id: 33111,
		name: "Curtis",
		nativeCurrency: {
			name: "ApeCoin",
			symbol: "APE",
			decimals: 18,
		},
		rpcUrls: {
			default: {
				http: ["https://curtis.rpc.caldera.xyz/http"],
				wss: ["wss://curtis.rpc.caldera.xyz/ws"],
			},
		},
		// sourceId: undefined;
		// testnet: undefined;
		// custom:undefined
		// formatters: undefined;
		// serializers: import("viem").ChainSerializers<undefined> | undefined;
		// fees?: import("viem").ChainFees<undefined> | undefined;
	},
] as const;

export const ChainId = {
	CURTIS: 33111,
	ETH_MAINNET: 1,
} as const;
export type ChainId = (typeof ChainId)[keyof typeof ChainId];

export const ChainKey = {
	33111: "curtis",
	1: "eth_mainnet",
} as const;
export type ChainKey = (typeof ChainKey)[keyof typeof ChainKey];
