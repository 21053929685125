import { IconComponent } from "@/ui/types";

export const External: IconComponent = (props) => {
	return (
		<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M21 11V3H13V5H17V7H15V9H13V11H11V13H9V15H11V13H13V11H15V9H17V7H19V11H21ZM11 5H3V21H19V13H17V19H5V7H11V5Z"
				fill="currentColor"
			/>
		</svg>
	);
};
